import React, { Component } from 'react'
import Header from "../layout/Header"
import Footer from "../layout/Footer"
import { connect } from "react-redux"
import axios from "axios";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ClinicalTrialSearch from './ClinicalTrialSearch'
import Methodology from './Methodology'
//import ReimbursementTable from './ReimbursementTable'
import { ClinicalTrialFetch } from "../../actions"
import Button from 'react-bootstrap/Button'
import TopMenu from "../layout/TopMenu"
import { isAdmin, isDev, SaveLog } from '../../utils'
import { Link } from "react-router-dom";
import {withAuth} from '../../actions/header_authorization';

class ClinicalTrial extends Component {
    constructor(props) {
        super(props);
        this.SearchClinical = this.SearchClinical.bind(this);
        this.togleMethodology = this.togleMethodology.bind(this);
        //this.toggleModal = this.toggleModal.bind(this);
        this.state = {
            name: "", therapeutic: "", administration: "", ShowModal: false, titleIndication: "", textIndication: "",
            very_high: true, high: true, medium: true, low: true, Methodology : false, log_reference :new Date().getTime()
        };
    }

    componentWillMount() {  //befor render first      
        SaveLog(this.state.log_reference, "Clinical trials tracking", true)
    } 
    componentWillUnmount(){   
        SaveLog(this.state.log_reference, "",  false)        
    } 

    togleMethodology(){
        this.setState({Methodology : true});        
        SaveLog(this.state.log_reference, "Click Methodology", false)
    }

    SearchClinical(...param) {
        this.setState({Methodology : false});
        //console.log(param);
        let query_param = {}
        if (param[0]) {
            this.setState({
                name: param[0], therapeutic: param[1], administration: param[2]
            });
            query_param['name'] = param[0];
            query_param['therapeutic'] = param[1];
            query_param['administration'] = param[2];
        } else {
            query_param['name'] = this.state.name;
            query_param['therapeutic'] = this.state.therapeutic;
            query_param['administration'] = this.state.administration;
        }
        let arr_level = [];
        if (document.getElementsByName('very_high')[0].checked) {
            arr_level.push('veryhigh');
        }
        if (document.getElementsByName('high')[0].checked) {
            arr_level.push('high');
        }
        if (document.getElementsByName('medium')[0].checked) {
            arr_level.push('medium');
        }
        if (document.getElementsByName('low')[0].checked) {
            arr_level.push('low');
        }
        if (arr_level.length > 0 && arr_level.length !== 4) {
            query_param['level'] = arr_level.join();
        }        
        SaveLog(this.state.log_reference, "Search : drugs = "+query_param['name'] + ", therapeutic area = " + query_param['therapeutic'] + ", administration = "+query_param['administration'] +(query_param['level'] !== undefined ? ", probability of filing = "+query_param['level'] :""), false)
        
        this.props.ClinicalTrialFetch(query_param);

    }

    levelhandleCheck(event) {

        SaveLog(this.state.log_reference, "Click : probability of filing = "+event.target.getAttribute('data-label') +  (event.target.checked ? "(check)" : "(uncheck)"), false)
        //console.log(event);
        //console.log(event.target.checked);
        //console.log(event.target.getAttribute('data-label'));
        //console.log(param); 
        

         setTimeout(
            function() {
                this.SearchClinical()
            }
            .bind(this),
            1000
        );
    }
    
    DownloadFile(){
        const url = this.props.clinical_trial_store.data.excel;          
        const typeFile = url.split('.').pop();
        const filename = "excel";
        

        axios.get(`${process.env.REACT_APP_API_LARAVEL}/api/file?path=${url}&type=response`, { headers : withAuth(), responseType: 'arraybuffer'})
        .then(res => {  
            if(res.headers['content-length'] == 51){
                
                alert("Error, The specified blob does not exist");
                return false;
            }
            const contentType = res.headers['content-type'];                    
            const blob = new Blob([res.data], {type: contentType});
            const objectFile = window.URL.createObjectURL(blob);    
           
            const link = document.createElement('a');
            link.href = objectFile;
            link.setAttribute('download', filename+"."+typeFile);
            document.body.appendChild(link);
            link.click();
            
        })
        .catch(error  => {            
            console.log(error);
        }); 

 
    }

    render() {
        //console.log(this.props.clinical_trial_store);

        const loop_row = []
        let link_excel = null
        const loop_year = []
        let listName = [];
        if (Object.entries(this.props.clinical_trial_store.data).length > 0) {
            //console.log(Object.entries(this.props.clinical_trial_store.data).length)
            const clinical_trial_data = this.props.clinical_trial_store.data.data;
            const clinical_trial_year = this.props.clinical_trial_store.data.year;
            link_excel = this.props.clinical_trial_store.data.excel;
            listName = this.props.clinical_trial_store.data.listName;


            const list_color = ['#1f3763', '#2e4e88', '#2e4e88d4', '#6881b0c2', '#8c9fc3b0', '#b0bdd6a8'];
            const list_color2 = ['#fff', '#fff', '#fff', '#fff'];
            
            for (const k_year in clinical_trial_year) {                
                 loop_year.push(<div key={k_year} className="list-child"><div className="box-square" style={{backgroundColor:list_color[k_year] , color:list_color2[k_year]}}><span>{clinical_trial_year[k_year]}</span></div></div>)

            }
            
            for (const drug_name in clinical_trial_data) {

                let loop_temp = []
                const arr_mono_combo = ['mono', 'combo']
                for (const K_mono_combo in arr_mono_combo) {
                    let mono_combo = arr_mono_combo[K_mono_combo]
                    loop_temp[mono_combo] = []

                    //console.log(mono_combo)
                    const maxRowArr = []
                    for (const year in clinical_trial_data[drug_name][mono_combo]) {
                        maxRowArr.push(clinical_trial_data[drug_name][mono_combo][year].length)
                    }
                    const maxRow = Math.max(...maxRowArr)
                    //console.log(maxRow)

                    
                    for (let i = 0; i < maxRow; i++) {


                        const loop_year = []
                        for (const k_year in clinical_trial_year) {
                            let year = clinical_trial_year[k_year]
                            if (clinical_trial_data[drug_name][mono_combo][year] && clinical_trial_data[drug_name][mono_combo][year][i]) {

                                loop_year.push(<div key={year} className="list-child">
                                    <div className="box-square-gray" style={{ border: '2px solid ' + clinical_trial_data[drug_name][mono_combo][year][i]['color'] }}>
                                        {clinical_trial_data[drug_name][mono_combo][year][i]['fdaema_probability'].toLowerCase() === "ema" && (
                                            <span className="flag size20 ema" ></span>
                                        )}
                                        {clinical_trial_data[drug_name][mono_combo][year][i]['fdaema_probability'].toLowerCase() === "fda" && (
                                            <span className="flag size20 us" ></span>
                                        )}
                                        <span> {clinical_trial_data[drug_name][mono_combo][year][i]['short_therapeutic'] + " " + clinical_trial_data[drug_name][mono_combo][year][i]['treatment_line']}  </span> <FontAwesomeIcon icon="info-circle" style={{ 'fontSize': 14, 'margin': "4px 5px 0px 20px", 'color': '#db1175', 'float': 'right', 'position': 'absolute', 'top': '5px', 'right': '8px' }} />
                                    </div>

                                    <div className="popover-content">
                                        <div className="popover-message">
                                            <ul>
                                                {/* <li><strong>Trial name</strong> : {clinical_trial_data[drug_name][mono_combo][year][i]['trial_name']}</li> */}
                                                <li><strong>Trial name</strong> : <a href={"https://clinicaltrials.gov/ct2/show/" + clinical_trial_data[drug_name][mono_combo][year][i]['nct_number']} target="_blank">{clinical_trial_data[drug_name][mono_combo][year][i]['trial_name']}</a></li>
                                                <li><strong>Phase</strong> : {clinical_trial_data[drug_name][mono_combo][year][i]['phase']}</li>

                                                {clinical_trial_data[drug_name][mono_combo][year][i]['primary_completion_date'] !== "" && (
                                                    <li><strong>Primary completion date</strong> : {clinical_trial_data[drug_name][mono_combo][year][i]['primary_completion_date']}</li>
                                                )}
                                                {clinical_trial_data[drug_name][mono_combo][year][i]['comment'] !== "" && (
                                                    <li><strong>Comment</strong> : {clinical_trial_data[drug_name][mono_combo][year][i]['comment']}</li>
                                                )}
                                                {clinical_trial_data[drug_name][mono_combo][year][i]['administration_monocombo'] !== "" && (
                                                    <li><strong>Administration </strong> : {clinical_trial_data[drug_name][mono_combo][year][i]['administration_monocombo']}</li>
                                                )}
                                                {clinical_trial_data[drug_name][mono_combo][year][i]['approval_fda'] !== "" && (
                                                    <li><strong>Approval (FDA)</strong> : {clinical_trial_data[drug_name][mono_combo][year][i]['approval_fda']}</li>
                                                )}
                                                {clinical_trial_data[drug_name][mono_combo][year][i]['approval_ema'] !== "" && (
                                                    <li><strong>Approval (EMA)</strong> : {clinical_trial_data[drug_name][mono_combo][year][i]['approval_ema']}</li>
                                                )}
                                                {clinical_trial_data[drug_name][mono_combo][year][i]['press_release_link'] !== "" && (
                                                    <li><strong>Press release link</strong> : <a href={clinical_trial_data[drug_name][mono_combo][year][i]['press_release_link']} target="_blank"> <FontAwesomeIcon icon="search" style={{ 'fontSize': 14, 'color': '#db1175', }} /></a></li>
                                                )}
                                                {clinical_trial_data[drug_name][mono_combo][year][i]['press_release_link_2'] !== "" && (
                                                    <li><strong>Press release link 2</strong> : <a href={clinical_trial_data[drug_name][mono_combo][year][i]['press_release_link_2']} target="_blank"> <FontAwesomeIcon icon="search" style={{ 'fontSize': 14, 'color': '#db1175', }} /></a></li>
                                                )}
                                            </ul>
                                        </div>
                                    </div>
                                </div>)



                            } else {

                                loop_year.push(<div key={year} className="list-child"></div>)

                            }



                        }


                        loop_temp[mono_combo].push(
                            <div key={i} className="box-list-alt box-list-child">
                                {loop_year}
                            </div>
                        )




                    }






                }
                loop_row.push(
                    <div key={drug_name} className="wrapper-box-list">
                        <div className="wrap-col">
                            <div className="box-col col-title"><span>{drug_name.toUpperCase()}</span></div>
                            <div className="box-col">
                                {loop_temp['mono'].length > 0 && this.state.administration !== "Combo" && (
                                    <div className="wrap-list-col wrap-cols">
                                        <div className="list-col"><span className="list-col-head">Mono</span></div>
                                        <div className="list-col col-right col-border">
                                            {loop_temp['mono']}
                                        </div>
                                    </div>
                                )}
                                {loop_temp['combo'].length > 0 && this.state.administration !== "Mono" && (
                                    <div className="wrap-list-col wrap-cols">
                                        <div className="list-col"><span className="list-col-head">Combo</span></div>
                                        <div className="list-col col-right col-border">
                                            {loop_temp['combo']}
                                        </div>
                                    </div>
                                )}
                            </div>

                        </div>
                    </div>
                )


            }
        }
        return (
            <div className="page-clinical-trial">
                <Header />
                <div className="container-fluid">

                    <div className="row">
                        <div className="box-tab-fixed w-100">
                            <TopMenu onSearch={this.SearchClinical} />
                            <ClinicalTrialSearch onSearch={this.SearchClinical} onMethodology={this.togleMethodology} methodology={this.state.Methodology} listName={listName} />
                        </div>
                    </div>

                    <div className="row row-table">                        
                        <div className="box-wrapper w-100 main-overlay" style={{ 'display' : (this.state.Methodology ? "none" : "block")}}>
                            <div className="clinical-control" > 
                                {isDev() && (
                                    <a onClick={() => this.DownloadFile()} className="item-control" target="_blank">
                                        <Button variant="secondary" style={{ 'marginBottom': '8px', 'float': 'right', 'marginRight': '8px' }}> DOWNLOAD </Button>
                                    </a>
                                )} 
                                {isAdmin() && (
                                    <Link to="/clinical_trial_update" className="item-control"><Button variant="secondary" style={{ 'marginBottom': '8px', 'float': 'right' }}> Update Data </Button></Link>
                                )}                                
                            </div>
                            <div className="box-contents">
                                <div className="bx-box-left">
                                <div className="border-bottom pb-3">
                                    <table className="table-menu">
                                    <tbody>
                                        <tr>                                        
                                        <td colSpan="2"> Probability of filing : </td>
                                        </tr>
                                        <tr>
                                        <td>
                                            <div className="squaredThree">
                                            <input type="checkbox" defaultChecked={true} id="checkOther1" data-label="Very High" name="very_high" onChange={(e) => this.levelhandleCheck(e)} />
                                            <label htmlFor="checkOther1" />
                                            </div>
                                        </td>
                                        <td><span className="bx-circle bg-very-high" /> Very High</td>
                                        </tr>
                                        <tr>
                                        <td>
                                            <div className="squaredThree">
                                            <input type="checkbox" defaultChecked={true} id="checkOther2" data-label="High" name="high" onChange={(e) => this.levelhandleCheck(e)} />
                                            <label htmlFor="checkOther2" />
                                            </div>
                                        </td>
                                        <td><span className="bx-circle bg-high" /> High</td>
                                        </tr>
                                        <tr>
                                        <td>
                                            <div className="squaredThree">
                                            <input type="checkbox" defaultChecked={true} id="checkOther3" data-label="Medium" name="medium" onChange={(e) => this.levelhandleCheck(e)} />
                                            <label htmlFor="checkOther3" />
                                            </div>
                                        </td>
                                        <td><span className="bx-circle bg-medium" /> Medium</td>
                                        </tr>
                                        <tr>
                                        <td>
                                            <div className="squaredThree">
                                            <input type="checkbox" defaultChecked={true} id="checkOther4" data-label="Low" name="low" onChange={(e) => this.levelhandleCheck(e)} />
                                            <label htmlFor="checkOther4" />
                                            </div>
                                        </td>
                                        <td><span className="bx-circle bg-low" /> Low</td>
                                        </tr>
                                    </tbody></table>
                                </div>
                                <div className="mt-3">
                                    <table className="table-menu">
                                    <tbody>
                                        <tr>                                        
                                        <td colSpan="2"> Filing : </td>
                                        </tr>
                                        <tr>
                                        <td><span className="flag size20 ema" style={{ 'marginRight': '5px' }} /> </td>
                                        <td>Ema only</td>
                                        </tr>
                                        <tr>
                                        <td><span className="flag size20 us" style={{ 'marginRight': '5px' }} /> </td>
                                        <td><span /> fda only</td>
                                        </tr>
                                        <tr>
                                        <td style={{fontSize:'0.7rem'}}>No flags</td>
                                        <td><span /> EMA + FDA</td>
                                        </tr>
                                    </tbody></table>
                                </div>
                                </div>
                                <div className="bx-box-right">
                                <div>
                                    <div className="wrap-col">
                                    <div className="box-col col-title" />
                                    <div className="box-col">
                                        <div className="wrap-list-col wrap-cols">
                                        <div className="list-col"><span className="list-col-head">&nbsp;</span></div>
                                        {loop_year.length > 0 && (
                                        <div className="list-col col-right col-border">
                                            <div className="box-list-alt box-list-child">
                                             {loop_year}
                                            </div>
                                        </div>
                                        )}
                                        </div>
                                    </div>
                                    </div>
                                    {loop_row}
                                </div>
                                </div>
                            </div>
                            {this.props.clinical_trial_store.loadding && (
                                <div className="overlay" >
                                    {/* <i class="fa fa-refresh fa-spin"></i> */}
                                </div>
                            )
                            }
                        </div>
    
                        

                        {this.state.Methodology && (    
                            <Methodology /> 
                        )}  
















                    </div>
                </div>
                <Footer />
            </div>
        )
    }
}


function mapStateToProps({ clinical_trial_store }) {
    return { clinical_trial_store };
}


export default connect(mapStateToProps, { ClinicalTrialFetch })(ClinicalTrial);